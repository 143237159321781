<template>
  <div>
    <v-card :loading="isLoading" class="mb-2">
      <v-app-bar flat v-if="can('edit-house-income')">
        <v-btn
          @click="handleTemplateDownloadClick"
          class="btn"
          color="pink"
          outlined
          small
          :loading="isLoading"
        >
          XLSX şablon indir
        </v-btn>

        <v-btn
          @click="handleTemplateUploadClick"
          class="btn ms-2"
          color="pink"
          outlined
          small
          :loading="isLoading"
        >
          XLSX şablon yükle
        </v-btn>

        <rs-file
          label="Dosya"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet"
          @change="handleFileChange"
          class="d-none"
          id="importInput"
        />
      </v-app-bar>

      <v-card-title>
        <v-icon class="mr-2">mdi-timeline-plus-outline</v-icon>
        Devir Alacak
      </v-card-title>

      <v-card-text v-if="clusterId">
        <v-form @submit.prevent="handleFormSubmit" ref="form">
          <v-row dense class="mt-5">
            <v-col>
              <v-data-table
                v-bind="dataTableAttrs"
                :headers="headers"
                :items="houses"
                :loading="isLoading"
                :options.sync="options"
                :items-per-page="20"
                :show-select="false"
                :item-class="houseRowClass"
                @pagination="handlePaginationUpdated"
              >
                <!-- eslint-disable-next-line -->
                <template v-slot:item.owner_amount="{ item, index }">
                  <rs-money-field
                    v-if="showAmounts"
                    v-model="item.owner_amount"
                    :rules="
                      item.disabled
                        ? []
                        : [
                            rules.required,
                            rules.min(item.owner_amount, 0.0),
                            rules.max(item.owner_amount, 9999999.99),
                          ]
                    "
                    :disabled="item.disabled"
                  />
                  <!-- @paste="handleAmountPaste($event, index)" -->
                </template>

                <!-- eslint-disable-next-line -->
                <template v-slot:item.renter_amount="{ item, index }">
                  <rs-money-field
                    v-if="showAmounts && item.renter"
                    v-model="item.renter_amount"
                    :rules="
                      item.disabled
                        ? []
                        : [
                            rules.required,
                            rules.min(item.renter_amount, 0.0),
                            rules.max(item.renter_amount, 9999999.99),
                          ]
                    "
                    :disabled="item.disabled"
                  />
                  <!-- @paste="handleAmountPaste($event, index)" -->
                </template>

                <!-- eslint-disable-next-line -->
                <template v-slot:item.activate="{ item }">
                  <v-btn
                    @click="handleHouseToggle(item)"
                    class="btn"
                    color="pink"
                    outlined
                    small
                  >
                    {{ item.disabled ? "Etkinleştir" : "İptal Et" }}
                  </v-btn>
                </template>

                <!-- eslint-disable-next-line -->
                <template v-slot:header.activate>
                  <v-btn
                    @click="handleToggleAllHouses()"
                    class="btn"
                    color="pink"
                    outlined
                    small
                  >
                    {{
                      houses.filter((item) => !item.disabled).length
                        ? "Tümünü İptal Et"
                        : "Tümünü Etkinleştir"
                    }}
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <rs-form-buttons
            :isLoading="isLoading"
            @cancel="handleCancelClick"
            @submit="handleFormSubmit"
            hide-submit
          />
        </v-form>
      </v-card-text>

      <v-card-text v-else>
        Devir alacak girebilmek için üst menüden toplu yaşam alanı seçin.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { hasDataTable, hasForm, hasPermissions } from "@/view/mixins";

export default {
  mixins: [hasDataTable, hasForm, hasPermissions],
  computed: {
    ...mapGetters(["blocks", "cluster", "clusterId", "houseTypes"]),
  },
  watch: {
    clusterId: {
      handler() {
        this.$nextTick(() => {
          this.populateHouseList();
        });
      },
      immediate: true,
    },
  },
  data() {
    return {
      houses: [],
      showAmounts: true,
      dataTableAttrs: {
        footerProps: {
          disableItemsPerPage: false,
        },
      },
      headers: [
        {
          text: "Blok",
          value: "block_name",
          sortable: false,
          searchable: false,
          options: () => this.blocks,
          width: "75px",
          align: "center",
        },
        {
          text: "Kapı Numarası",
          value: "door_number",
          sortable: false,
          searchable: "text",
          width: "75px",
          align: "center",
        },
        {
          text: "BB Tipi",
          value: "house_type_name",
          sortable: false,
          searchable: "select",
          options: () => this.houseTypes,
          width: "75px",
          align: "center",
        },
        {
          text: "Ev Sahibi",
          value: "owner",
          sortable: false,
          searchable: "text",
        },
        {
          text: "Ev Sahibi Alacak",
          value: "owner_amount",
          sortable: false,
          searchable: false,
        },
        {
          text: "Kiracı",
          value: "renter",
          sortable: false,
          searchable: "text",
        },
        {
          text: "Kiracı Alacak",
          value: "renter_amount",
          sortable: false,
          searchable: false,
        },
        {
          text: "",
          value: "activate",
          sortable: false,
          searchable: false,
        },
      ],
    };
  },
  methods: {
    populateHouseList() {
      if (!this.clusterId) {
        return false;
      }

      const params = {
        is_active: 1,
      };
      const formList = [];
      this.houses = [];

      this.isLoading = true;

      params.per_page = 2000;
      params.order_by = "door_number";
      params.order = "asc";

      this.$api
        .query(`clusters/${this.clusterId}/houses`, { params })
        .then((response) => {
          for (const index in response.data.data) {
            const item = response.data.data[index];

            formList.push({
              house_id: item.id,
              house_type_name: item.house_type_name,
              door_number: item.door_number,
              block_name: item.block_name,
              renter: item.renter,
              owner: item.owner,
              has_multiple_owners: item.has_multiple_owners,
              owner_amount: 0.0,
              renter_amount: 0.0,
              disabled: false,
            });
          }

          this.houses = formList;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const formData = { cluster_id: this.clusterId };

      formData.houses = this.houses.filter((item) => {
        return !item.disabled;
      });

      if (formData.houses.length === 0) {
        this.$toast.error("En az bir bağımsız bölüm aktif olmalı.");
        return false;
      }

      this.isLoading = true;

      if (formData.calculation_type_id === 1) {
        delete formData.block_ids;
      }

      this.$api
        .post(`income/batch-transfer-due`, formData)
        .then(() => {
          this.$toast.success("Kaydedildi");

          if (event && event.closeOnSave) {
            this.handleCancelClick();
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleTemplateDownloadClick() {
      if (this.isLoading) return false;
      this.isLoading = true;

      const params = {
        is_active: 1,
        cluster_id: this.clusterId,
        debitor_type_id: this.formData.debitor_type_id,
      };

      if (this.formData.calculation_type_id === 2) {
        params.block_name = this.formData.block_ids;
      }

      this.$api
        .query("income/transfer-due-template", { params })
        .then((response) => {
          const anchor = document.createElement("a");
          anchor.href = response.data.data.url;
          anchor.target = "_blank";
          // anchor.download =
          //   response.data.data.title + "." + response.data.data.extension;
          anchor.click();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleTemplateUploadClick() {
      document.querySelector("input#importInput").value = null;
      document.querySelector("input#importInput").click();
    },
    handleFileChange(file) {
      if (!file) {
        return;
      }

      const allowedMimes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.oasis.opendocument.spreadsheet",
      ];

      if (allowedMimes.indexOf(file.type) === -1) {
        this.$toast.error("Sadece XLSX veya ODS yükleyebilirsiniz.");
        return;
      }

      this.isLoading = true;
      const data = new FormData();
      data.append("file", file);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      this.$api
        .post("read-spreadsheet", data, config)
        .then((response) => {
          for (const index in response.data.data) {
            for (const houseIndex in this.houses) {
              if (
                this.houses[houseIndex].house_id ===
                parseInt(response.data.data[index][0])
              ) {
                const ownerAmount = parseFloat(response.data.data[index][5]);
                const renterAmount = parseFloat(response.data.data[index][7]);

                if (ownerAmount > 0.0) {
                  this.houses[houseIndex].owner_amount = ownerAmount;
                } else {
                  this.houses[houseIndex].owner_amount = null;
                }

                if (this.houses[houseIndex].renter && renterAmount > 0.0) {
                  this.houses[houseIndex].renter_amount = renterAmount;
                } else {
                  this.houses[houseIndex].renter_amount = null;
                }

                if (
                  ownerAmount <= 0.0 &&
                  (!this.houses[houseIndex].renter || renterAmount <= 0.0)
                ) {
                  this.houses[houseIndex].disabled = true;
                }
                continue;
              }
            }
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handlePaginationUpdated() {
      this.showAmounts = false;
      setTimeout(() => (this.showAmounts = true), 50);
    },
    houseRowClass(item) {
      return item.disabled ? "disabled" : null;
    },
    handleToggleAllHouses() {
      let value = false;

      if (this.houses.filter((item) => !item.disabled).length) {
        value = true;
      }

      for (const index in this.houses) {
        this.houses[index].disabled = value;
      }
    },
    handleHouseToggle(item) {
      item.disabled = !item.disabled;
      item.amount = 0;
    },
  },
};
</script>
